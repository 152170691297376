import { ApiClient } from "@anna-money/anna-web-lib";
import { type NavigateFunction } from "react-router-dom";
import { Memoize } from "typescript-memoize";
import { AbnStartDateFormStore } from "services/abnStartDateForm/abnStartDateFormStore";
import { CompanyStateFormStore } from "services/companyStateForm/companyStateFormStore";
import { MainBusinessActivityFormStore } from "services/mainBusinessActivityFormStore/mainBusinessActivityFormStore";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { BaseServices, Services } from "./services";
import { AcnRegisterFormStepsStore } from "./services/acnRegisterForm/acnRegisterFormStepsStore";
import { AcnRegisterFormStore } from "./services/acnRegisterForm/acnRegisterFormStore";
import { BillingItemsStore } from "./services/billingItems/au/billingItemsStore";
import { BusinessActivitiesSelectStore } from "./services/businessActivitiesSelect/au/businessActivitiesSelectStore";
import { CheckoutBasketStore } from "./services/checkoutBasket/au/checkoutBasketStore";
import { CompanyAddressStore } from "./services/companyAddress/au/companyAddressStore";
import { CompanyAddressFormStore } from "./services/companyAddressForm/au/companyAddressFormStore";
import { CompanyBusinessActivitiesStore } from "./services/companyBusinessActivities/au/companyBusinessActivitiesStore";
import { CompanyNameFormStore } from "./services/companyNameForm/au/companyNameFormStore";
import { FeatureStore } from "./services/feature/featureStore";
import { PricingPlanStore } from "./services/pricingPlan/au/pricingPlanStore";
import { PricingPlanClient } from "./services/pricingPlan/pricingPlanClient";
import { PricingPlanFormStore } from "./services/pricingPlanForm/au/pricingPlanFormStore";
import { ProfileStore } from "./services/profile/profileStore";
import { AbnQuestionsStore } from "./services/questions/au/abnQuestionsStore";
import { CompanyQuestionsStore } from "./services/questions/au/companyQuestionsStore";
import { DirectorQuestionsStore } from "./services/questions/au/directorQuestionsStore";
import { IdentityQuestionsStore } from "./services/questions/au/identityQuestionsStore";
import { SignUpPageStore } from "./services/sighUpPage/au/signUpPageStore";
import { AbnStepStore } from "./services/stepStore/au/abnStepStore";
import { CompanyAcnStepStore } from "./services/stepStore/au/companyAcnStepStore";
import type { UserClient } from "./services/user/userClient";
import { UserStore } from "./services/user/userStore";

export class ServicesAu extends Services {
  constructor(
    protected readonly _baseServices: BaseServices,
    readonly _authenticationHandler: AuthenticationHandler,
    readonly _apiClient: ApiClient,
    readonly _userClient: UserClient,
    readonly _userStore: UserStore,
    readonly _profileStore: ProfileStore,
    readonly _featureStore: FeatureStore,
    readonly _navigate: NavigateFunction,
  ) {
    super(
      _baseServices,
      _authenticationHandler,
      _apiClient,
      _userClient,
      _userStore,
      _profileStore,
      _featureStore,
      _navigate,
    );
  }

  @Memoize()
  get abnStepStore(): AbnStepStore {
    return new AbnStepStore(this.companyStore, this.companyBusinessActivitiesStore);
  }

  @Memoize()
  get abnQuestionsStore(): AbnQuestionsStore {
    return new AbnQuestionsStore(this.companyStore, this.issuesStore);
  }

  @Memoize()
  get companyAddressStore(): CompanyAddressStore {
    return new CompanyAddressStore(
      this.companyStore,
      this.addressClient,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get companyStepStore(): CompanyAcnStepStore {
    return new CompanyAcnStepStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get companyBusinessActivitiesStore(): CompanyBusinessActivitiesStore {
    return new CompanyBusinessActivitiesStore(this.companyStore, this.addressClient);
  }

  @Memoize()
  get billingItemsStore(): BillingItemsStore {
    return new BillingItemsStore();
  }

  @Memoize()
  get pricingPlanStore(): PricingPlanStore {
    return new PricingPlanStore(this.companyStore, this.companyProductsStore, new PricingPlanClient(this.apiClient));
  }

  @Memoize()
  get companyQuestionsStore(): CompanyQuestionsStore {
    return new CompanyQuestionsStore(this.companyStore, this.companyAddressStore, this.issuesStore);
  }

  @Memoize()
  get directorQuestionsStore(): DirectorQuestionsStore {
    return new DirectorQuestionsStore(this.directorStore, this.directorAddressStore, this.issuesStore);
  }

  @Memoize()
  get identityQuestionsStore(): IdentityQuestionsStore {
    return new IdentityQuestionsStore(this.addressVerificationStore, this.sumsubApplicationStore, this.issuesStore);
  }

  @Memoize()
  get acnRegisterFormFormStepsStore(): AcnRegisterFormStepsStore {
    return new AcnRegisterFormStepsStore(
      this.companyQuestionsStore,
      this.abnQuestionsStore,
      this.directorQuestionsStore,
      this.identityQuestionsStore,
      this.analyticsManager,
      this.profileStore,
    );
  }

  createAbnStartDateFormStore = (): AbnStartDateFormStore => new AbnStartDateFormStore(this.companyStore);

  createCheckoutBasketStore = (): CheckoutBasketStore =>
    new CheckoutBasketStore(this.companyStore, this.companyProductsStore);

  createCompanyAddressFormStore = (): CompanyAddressFormStore => new CompanyAddressFormStore(this.companyAddressStore);

  createCompanyNameFormStore = (): CompanyNameFormStore => new CompanyNameFormStore(this.companyStore);

  createCompanyStateFormStore = (): CompanyStateFormStore => new CompanyStateFormStore(this.companyStore);

  createCompanyBusinessActivitiesStore = (): MainBusinessActivityFormStore =>
    new MainBusinessActivityFormStore(this.companyBusinessActivitiesStore);

  createPricingPlanFormStore = (): PricingPlanFormStore => {
    return new PricingPlanFormStore(this.pricingPlanStore);
  };

  createAcnRegisterFormStore = (): AcnRegisterFormStore => {
    return new AcnRegisterFormStore(
      this.directorStore,
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.paymentMethodsStore,
      this.consentStore,
      this.companyBusinessActivitiesStore,
      this.businessActivitiesStore,
      this.pricingPlanStore,
      this.userStore,
      this.sumsubApplicationStore,
      this.analyticsManager,
      this.acnRegisterFormFormStepsStore,
      this.incorporationStore,
      this.issuesStore,
      this.companyStepStore,
      this.abnStepStore,
    );
  };

  createSignUpPageStore = (): SignUpPageStore =>
    new SignUpPageStore(
      this.companyClient,
      this.leadClient,
      this.analyticsManager,
      this.userStore,
      this._navigate,
      this.config,
    );

  createBusinessActivitiesSelectStore = (): BusinessActivitiesSelectStore =>
    new BusinessActivitiesSelectStore(this.businessActivitiesClient, this.businessActivitiesStore, this.companyStore);
}
