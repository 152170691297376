import { useMemo } from "react";
import { useServices } from "appServices";
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from "helpers/date";
import { addressDataToForm } from "helpers/forms/address";
import { type AddressData } from "services/address/addressTypes";
import { type PersonalAttributeData } from "services/attributes/attributesTypes";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { DirectorAddressKey } from "services/director/directorAddressStore";
import { DirectorCreateData, type DirectorData, DirectorUpdateData } from "services/director/directorTypes";
import {
  DirectorPosition,
  type DirectorPositionStore,
  isDirector,
} from "services/directorPosition/directorPositionStore";
import { type DirectorStepFormValues } from "./directorTypes";

const getDirectorPosition = (
  directorId: string | undefined,
  isMainDirector: boolean,
  directorPositionsStore: DirectorPositionStore,
): DirectorPosition | undefined => {
  if (isMainDirector) {
    return DirectorPosition.DirectorAndShareholder;
  }

  if (!directorId) {
    return undefined;
  }

  return directorPositionsStore.getForDirector(directorId);
};

export const isAustralia = (country?: string): boolean => {
  return country?.toLocaleLowerCase()?.includes("australia") ?? false;
};

export const directorDataToDirectorForm = (
  director?: DirectorData,
  address?: AddressData,
  previousAddress?: AddressData,
  attributes?: PersonalAttributeData[],
  position?: DirectorPosition,
  hasCancellationCover?: boolean,
  hasPayeRegistration?: boolean,
  hasManagementSecretary?: boolean,
): DirectorStepFormValues => {
  return {
    firstName: director?.firstName ?? undefined,
    middleNames: director?.middleNames ?? undefined,
    lastName: director?.lastName ?? undefined,
    dob: director?.dob ? formatDateDDMMYYYY(director.dob) : undefined,
    nationality: director?.nationality ?? undefined,
    address: addressDataToForm(address),
    previousAddressRequired: previousAddress ? true : undefined,
    previousAddress: addressDataToForm(previousAddress),
    attributes: attributes?.map((attribute) => ({
      value: attribute.value,
      type: attribute.type,
    })),
    position,
    managementSecretary: hasManagementSecretary,
    cancellationCover: hasCancellationCover,
    payeRegistration: hasPayeRegistration,
    countryOfBirth: director?.countryOfBirth ?? undefined,
    birthSuburb: director?.birthSuburb ?? undefined,
    townOfBirth: director?.townOfBirth ?? undefined,
    tfn: director?.tfn ?? undefined,
  };
};

export const directorFormToDirectorCreateData = (
  companyId: string,
  directorForm: DirectorStepFormValues,
): DirectorCreateData => {
  return new DirectorCreateData(
    companyId,
    directorForm.firstName,
    directorForm.middleNames,
    directorForm.lastName,
    directorForm.dob ? formatDateYYYYMMDD(directorForm.dob) : undefined,
    directorForm.nationality,
    isDirector(directorForm.position),
  );
};

export const directorFormToDirectorUpdateData = (directorForm: DirectorStepFormValues): DirectorUpdateData => {
  return new DirectorUpdateData(
    directorForm.firstName,
    directorForm.middleNames,
    directorForm.lastName,
    directorForm.dob ? formatDateYYYYMMDD(directorForm.dob) : undefined,
    directorForm.nationality,
    isDirector(directorForm.position),
    directorForm.countryOfBirth,
    isAustralia(directorForm.countryOfBirth) ? directorForm.birthSuburb : undefined,
    isAustralia(directorForm.countryOfBirth) ? directorForm.townOfBirth : undefined,
    directorForm.tfn,
  );
};

export const formatDirectorName = (director?: DirectorData): string => {
  if (!director) {
    return "";
  }

  let fullName = director.firstName;

  if (director.middleNames) {
    fullName = `${fullName} ${director.middleNames}`;
  }

  return `${fullName} ${director.lastName || ""}`;
};

export function useDirectorFormInitialValues(
  directorId?: string,
  isMainDirector: boolean = false,
): DirectorStepFormValues {
  const { directorStore, directorAttributesStore, directorAddressStore, directorPositionsStore, companyProductsStore } =
    useServices();
  return useMemo(() => {
    return directorDataToDirectorForm(
      directorId ? directorStore.tryGetDirectorById(directorId) : undefined,
      directorId ? directorAddressStore.tryGetForDirector(directorId) : undefined,
      directorId ? directorAddressStore.tryGetForDirector(directorId, DirectorAddressKey.Previous) : undefined,
      directorId ? directorAttributesStore.tryGetForDirector(directorId) : undefined,
      getDirectorPosition(directorId, isMainDirector, directorPositionsStore),
      companyProductsStore.hasProduct(CompanyProduct.CancellationCover) || undefined,
      companyProductsStore.hasProduct(CompanyProduct.PayeRegistration) || undefined,
      companyProductsStore.hasProduct(CompanyProduct.DirectoryManagement) || undefined,
    );
  }, [directorId]);
}
